import {doVxqlRequest} from './common.js';

// GraphQL fragments for the gained reward types
const GAINED_REWARD_FRAGMENTS = `
fragment GainedRewardBase on VXGameGainedReward {
  gainedAt
  type
  imageUrl
}

fragment GainedVideoRewardFields on GainedVideoReward {
  ...GainedRewardBase
  video {
    id
    title
    description
    url(profile: hls)
    linkVX
    model {
      id
      name
    }
  }
}

fragment GainedBonusCodeRewardFields on GainedBonusCodeReward {
  ...GainedRewardBase
  bonusCode
  canRedeem
}

fragment GainedCoinsRewardFields on GainedCoinsReward {
  ...GainedRewardBase
  coinsAmount
}

fragment GainedVideoVoucherRewardFields on GainedVideoVoucherReward {
  ...GainedRewardBase
  voucherAmount
}

fragment GainedSpinsRewardFields on GainedSpinsReward {
  ...GainedRewardBase
  spinsAmount
}

fragment GainedMessengerGiftRewardFields on GainedMessengerGiftReward {
  ...GainedRewardBase
  messengerGiftAmount
  messengerGift {
    id
    name
    active
    imageUrl
    translations {
      language
      name
      value
    }
  }
}

fragment GuestInfoFields on VXGameGuestInfo {
  gainedRewards {
    ...GainedRewardBase
    ...GainedVideoRewardFields
    ...GainedBonusCodeRewardFields
    ...GainedCoinsRewardFields
    ...GainedVideoVoucherRewardFields
    ...GainedSpinsRewardFields
    ...GainedMessengerGiftRewardFields
  }
  points
  attemptsLeftToday
}
`;

const USE_ATTEMPT_RESULT_FIELDS = `
fragment UseAttemptResultFields on VXGameUseAttemptResult {
  reward {
    id
    ...GainedRewardBase
    ...GainedVideoRewardFields
    ...GainedBonusCodeRewardFields
    ...GainedCoinsRewardFields
    ...GainedVideoVoucherRewardFields
    ...GainedSpinsRewardFields
    ...GainedMessengerGiftRewardFields
  }
  guestInfo {
    ...GuestInfoFields
  }
}
`;

const loadConfig = (configId, callback) => {
    const variables = {
        configId,
    };

    const query = `
${GAINED_REWARD_FRAGMENTS}
query VXGAME($configId: Int!) {
  vxGame {
    gameInfo(id: $configId) {
      config {
        id
        startDate(format: "d.m.Y")
        startDateShort: startDate(format: "d.m.")
        endDate(format: "d.m.Y")
        hasStarted
        hasEnded
        name
        dailyAttempts
        gameType
        gameCategory
        dayConfigs {
          id
          day
          dailyAttempts
          pointsPerAttempt
        }
        langConfigs {
          id
          lang
          rewards {
            reward {
              id
              type
              imageUrl
              active
              ... on VideoRewardConfig {
                albumId
                imageUrl
              }
              ... on BonusCodeRewardConfig {
                bonusCode
                imageUrl
              }
              ... on CoinsRewardConfig {
                coinsAmount
                imageUrl
              }
              ... on VideoVoucherRewardConfig {
                voucherAmount
                imageUrl
              }
              ... on SpinsRewardConfig {
                spinsAmount
                imageUrl
              }
              ... on MessengerGiftRewardConfig {
                messengerGiftId
                messengerGiftAmount
                imageUrl
              }
            }
          }
          actors {
            id
            name
            linkVX
            online
            avatar {
              url(size: w320)
            }
          }
          ... on SlotmachineLangConfig {
            subtitleText
            leadInText
            leadInTimeframeText
            buttonText
            descriptionText
            redemptionInfoText
            girlsText
            backgroundImage
            backgroundMobileImage
            logoImage
            logoMobileImage
            modalBackgroundImage
            modalBackgroundMobileImage
            slotmachineImage
            slotmachineMobileImage
          }
        }
      }
      guestInfo {
        ...GuestInfoFields
      }
    }
  }
}
`;

    doVxqlRequest(query, variables).then(callback);
};

const useAttempt = (configId, callback) => {
    const variables = {
        configId,
    };

    const query = `
${GAINED_REWARD_FRAGMENTS}
${USE_ATTEMPT_RESULT_FIELDS}

mutation VXGAME_USE($configId: Int!) {
  guest {
    vxGame {
      useAttempt(configId: $configId) {
        result {
          ...UseAttemptResultFields
        }
        error {
          errorMessage
        }
      }
    }
  }
}
`;

    doVxqlRequest(query, variables).then((response) => {
        if (response.data) {
            const data = response.data.guest.vxGame.useAttempt.result;
            callback(data);
        } else {
            callback(null);
        }
    });
};

export default {
    loadConfig,
    useAttempt
};
