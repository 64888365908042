/* global VXConfig */

import Constants                                from '../../Constants.js';
import {doFetch, existsApiCookie, urlWithQuery} from '../../../utils/CommonUtils.js';

const VXQL_URL          = VXConfig.vxqlUrl;
const VXQL_ACCESS_TOKEN = VXConfig.vxqlAccessToken;
const LANG              = VXConfig.language;

/**
 *
 * @param {string} query
 * @param {Object} variables
 * @param {Object} queryParam
 * @returns {Promise}
 */
function doVxqlRequest(query, variables = null, queryParam = {}) {
    if (!VXQL_ACCESS_TOKEN) {
        return Promise.reject(new Error('Missing Vxql-Token'));
    }

    const queryObject = {};
    queryObject.query = query;

    const queryString = window.location.search;
    const urlParams   = new URLSearchParams(queryString);

    if (variables) {
        queryObject.variables = variables;
    }

    const init = {
        headers: {'Authorization': 'Bearer ' + VXQL_ACCESS_TOKEN},
    };

    const params = {...queryParam, language: LANG};

    if (urlParams.get('qmdate')) {
        params.qmdate = urlParams.get('qmdate');
    }

    if (urlParams.get('qmDay')) {
        params.qmday = urlParams.get('qmday');
    }

    const requestUrl = urlWithQuery(VXQL_URL, params);

    return doFetch(requestUrl, queryObject, Constants.HttpMethods.POST, existsApiCookie(), init);
}

export {
    doVxqlRequest,
    LANG
};
